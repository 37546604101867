<template>
  <div class="voteDetail_container global_vote_font" ref="voteDetail">
    <div>
      <div class="voteDetail_title" :class="getColor()">
        参赛课题: {{ data.courseware_name }}
      </div>
      <div class="voteDetail_card">
        <div class="voteDetail_card_block" :class="getCardStyle()">
          <div class="voteDetail_card_block_card">
            <div class="voteDetail_card_left">
              <div class="doctor_name voteDetail_card_item">
                专家: {{ data.doctor_name }}
              </div>
              <div class="doctor_hospital voteDetail_card_item">
                医院: {{ data.doctor_hospital }}
              </div>
              <div
                class="vote_number voteDetail_card_item"
                :class="getColor()"
                v-if="this.mode"
              >
                专家票数: {{ data.vote_count_doctor }}票
              </div>
              <div
                class="vote_number voteDetail_card_item"
                :class="getColor()"
                v-if="this.mode"
              >
                大众票数: {{ data.vote_count_user }}票
              </div>
              <div class="voteDetail_card_item" v-if="!this.mode">
                当前总数 : {{ data.vote_count }}票
              </div>
              <div
                class="vote_number voteDetail_card_item"
                v-if="this.mode"
                :class="getColor()"
              >
                当前票数: {{ data.vote_count }}票
              </div>
              <div class="rank voteDetail_card_item" v-if="this.mode">
                当前排名:
                <span :class="getColor()">{{ data.rank }}</span>
              </div>
              <div class="voteDetail_card_item" v-if="!this.mode">
                当前排名: {{ data.rank }}
              </div>
              <div class="desc voteDetail_card_item" v-if="this.mode">
                专家票数1票为10票
              </div>
            </div>
            <div class="voteDetail_card_right">
              <img v-if="!mode" :src="data.profile" class="avater" />
              <img
                v-if="mode"
                :src="data.profile"
                :class="!role ? 'avater' : 'avater_new'"
              />
              <img
                v-if="data.rank === 1 && data.vote_count !== 0 && !isCanVote()"
                :src="
                  !role
                    ? require('@/assets/images/challenge/trophy.png')
                    : require('@/assets/images/challenge/trophy-1.png')
                "
                class="trophy"
              />
            </div>
          </div>
          <div
            class="voteDetail_handle_vote_block"
            :class="getBtnColor()"
            @click="handleVote(data)"
          >
            {{ getVoteBtnTitle() }}
          </div>
        </div>
      </div>
      <div class="voteDetail_play_block">
        <videoPlayer
          :videoURL="data.share_url"
          ref="videoRef"
          @playEnd="playEnd"
          :cover="data.cover"
        ></videoPlayer>
        <!-- <video
          :src="data.share_url"
          controls
          @click="player"
          controlslist="nodownload"
          ref="videoRef"
          @ended="playEnd"
        ></video>
        <div @click="player" v-if="!isPlay" class="voteDetail_play_cover">
          <div class="play_module" ref="moduleRef"></div>
          <img ref="posterRef" class="play_posterImg" :src="data.cover" />
          <div class="voteDetail_play_icon">
            <img
              class="voteDetail_play_icon"
              :src="require('@/assets/images/challenge/play.png')"
            />
          </div>
        </div> -->
      </div>
      <div class="voteDetail_play_go_back">
        <el-button
          class="return_btn"
          :class="getbackBgColor()"
          size="mini"
          round
          type="primary"
          icon="el-icon-arrow-left"
          @click="handleGoBack"
          >返回大赛主页</el-button
        >
      </div>
      <voteFooter :mode="mode" :role="role"></voteFooter>
    </div>
  </div>
</template>

<script>
import tool from "@/common/tool";
import wx from "@/common/weixin";
import urls from "@/common/urls";
import config from "@/common/config";
import BSScroll from "better-scroll";
import voteFooter from "./voteFooter.vue";
import videoPlayer from "@/components/unit/videoPlayer.vue";
import { ElButton } from "element-plus";
export default {
  data() {
    return {
      voteStatus: false,
      data: {},
      isPlay: false,
      count: 0,
      voteList: [],
      timer: null,
      internalVote: "",
      role: null,
      mode: null,
      doctorInfo: {},
    };
  },

  components: {
    voteFooter,
    videoPlayer,
    ElButton,
  },

  created() {
    this.role = Number(this.$route.query.role);
    this.mode = Number(this.$route.query.mode);
    this.doctorInfo = this.$store.state.common.doctorInfo || {}; //肺癌挑战赛医生信息
    this.infoData = this.$store.state.common.infoData || {}; //百研小安医生信息
    this.initScroll();
    this.loadData();
  },
  mounted() {
    this.internalVote = Number(tool.getStorage("internalVote", sessionStorage));
    document.getElementsByTagName("title")[0].innerHTML =
      this.mode && this.role ? "专家互评区" : "我要投票";
  },
  methods: {
    getVoteBtnTitle() {
      if (this.mode && this.role) {
        if (this.count) {
          return "已投票";
        }
        return "我要投专家票";
      } else if (this.mode && !this.role) {
        if (this.count) {
          return "已投票";
        }
        return "我要投票";
      } else {
        return "我要投票";
      }
    },
    //组数据样式
    getCardStyle() {
      if (!this.mode) {
        return "card_block_shadow";
      } else if (!this.role) return "card_block_shadow";
      else return "card_block_shadow_new";
    },
    getColor() {
      if (!this.mode) return "color"; //旧模式
      else if (!this.role) return "color";
      else return "color_new";
    },
    getbackBgColor() {
      if (!this.mode) return "group_vote_btn"; //旧模式
      else if (!this.role) return "group_vote_btn";
      else return "group_vote_btn_new";
    },
    getBtnColor() {
      if (!this.mode) {
        if (
          !this.isCanVote() ||
          this.getCurrDoctor() == this.data.class_group ||
          this.isDisabled()
        ) {
          return "voteDetail_handle_vote_disabled";
        }
        return "voteDetail_handle_vote";
      } else {
        if (!this.role) {
          if (
            !this.isCanVote() ||
            this.getCurrDoctor() == this.data.class_group ||
            this.isDisabled()
          ) {
            return "voteDetail_handle_vote_disabled";
          }
          return "voteDetail_handle_vote";
        } else {
          if (
            !this.isCanVote() ||
            this.getCurrDoctor() == this.data.class_group ||
            this.isDisabled()
          ) {
            return "voteDetail_handle_vote_disabled";
          }
          return "proficDetail_handle_vote";
        }
      }
    },
    playEnd() {
      this.isPlay = false;
      // this.$refs.videoRef.currentTime = 0;
      // this.$refs.posterRef.style.display = "block";
      // this.$refs.moduleRef.style.display = "bolck";
    },
    // playVideo(url) {
    //   if (!url) return;
    //   window.open(url);
    // },

    initScroll() {
      this.$nextTick(() => {
        if (!this.scroll) {
          this.scroll = new BSScroll(this.$refs.voteDetail, {
            click: true,
            mouseWheel: true,
          });
        } else {
          this.scroll.refresh();
        }
      });
    },
    initParams() {
      let params = {
        title: `${this.data.sign_project}${this.data.courseware_name}`,
        desc: `我是${this.data.doctor_name}医生,来自${this.data.doctor_hospital}。\n快来为我点赞加油吧!`,
        url: "",
        logo: this.data.profile,
      };
      //分享
      wx.shareMessage(params);
    },
    isDisabled() {
      if (this.count < this.data.vote_people_max_num) {
        if (this.voteList.includes(this.data.id)) return false;
        if (this.voteList.length < this.data.vote_people_num) return false;
      }
      return true;
    },
    handleGoBack() {
      this.$router.push("challenge");
    },
    //判断是否是本组专家
    getCurrDoctor() {
      if (this.doctorInfo.name) {
        let project_id = config.project_id;
        let project_info = this.doctorInfo.project_info.find((item) => {
          if (item.project_id == project_id) return item;
        });
        if (project_info) {
          for (
            let index = 0;
            index < project_info.period_info.length;
            index++
          ) {
            if (project_info.period_info[index].period == this.data.period) {
              return project_info.period_info[index].class_group[0];
            }
          }
        }
      }
    },

    //投票
    handleVote(item) {
      if (!this.role && this.infoData.name) {
        return this.$tips.error({ text: "您是已注册专家，请点击专家投票专区" });
      } else if (this.role && !this.infoData.name) {
        return this.$tips.error({
          text: "您不是已注册专家，请点击大众投票专区",
        });
      }
      if (this.timer && Date.now() - this.timer < 1000) return;
      this.timer = Date.now();
      if (this.internalVote !== 1) {
        if (tool.getTime(this.data.start_time) > Date.now())
          return this.$tips.error({ text: "本期投票未开始" });
        if (tool.getTime(this.data.end_time) < Date.now())
          return this.$tips.error({ text: "本期投票已结束" });
      }
      //本组专家 不能投
      if (this.getCurrDoctor() == this.data.class_group) {
        return this.$tips.error({ text: "您是本组参赛专家,不可参与同组投票" });
      }
      if (this.isDisabled()) {
        if (this.voteList.length >= this.data.vote_people_num)
          return this.$tips.error({
            text: "您今日投票次数已用完，请明天再为喜爱的专家投票！",
          });
        return this.$tips.error({ text: "已投票,请为其他专家投票" });
      }
      item = { ...item, openid: this.doctorInfo.openid };
      this.role
        ? (item = { ...item, count: 10, type: 1 })
        : (item = { ...item, count: 1, type: 0 });
      let url = tool.getURL(urls.vote.vote);
      this.$axios.post(url, item).then((res) => {
        if (res.errcode) return;
        this.$tips.success({ text: "投票成功" });
        this.role
          ? (this.data.vote_count = this.data.vote_count + 10)
          : (this.data.vote_count += 1);
        this.role
          ? (this.data.vote_count_doctor += 1)
          : (this.data.vote_count_user += 1);
        this.count += 1;
      });
    },
    //判断是否在投票时间内
    isCanVote() {
      return (
        tool.getTime(this.data.start_time) < Date.now() &&
        tool.getTime(this.data.end_time) > Date.now()
      );
    },
    player() {
      if (this.isPlay === false) {
        this.$refs.videoRef.play();
        // this.$refs.posterRef.style.display = "none";
        // this.$refs.moduleRef.style.display = "none";
        this.isPlay = true;
      } else {
        this.$refs.videoRef.pause();
        // this.$refs.moduleRef.style.display = "block";
        // this.isPlay = false;
      }
    },
    //我的投票
    loadMyVote() {
      let url = tool.getURL(urls.vote.myVote, {
        company_id: this.data.company_id,
        project_id: this.data.project_id,
        period: this.data.period,
      });
      this.$axios.get(url).then((res) => {
        console.log(res.data);
        let list = res.data.filter(
          (item) => tool.getTime(item.subdate) >= tool.getTime(tool.getDate())
        );
        let currentVote = list.find(
          (item) => item.vote_id == this.$route.query.vote_id
        );
        this.count = currentVote ? currentVote.count : 0; //对当前课件的投票次数
        this.voteList = Array.from(new Set(list.map((x) => x.vote_id))); //已投课件id
        this.$nextTick(() => {
          this.initScroll();
          this.initParams();
        });
      });
    },
    //数据详情
    loadData() {
      let query = this.$route.query;
      let url = tool.getURL(urls.vote.voteDetail, query);
      this.$axios.get(url).then((res) => {
        this.data = res.data;
        this.data.vote_count = this.data.vote_count ? this.data.vote_count : 0;
        this.loadMyVote();
      });
    },
  },
};
</script>

<style >
.voteDetail_container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  background: #f2f2f3;
  padding-top: 2.67vw;
  overflow: hidden;
  padding-bottom: 15vw;
}
.voteDetail_title {
  margin: 0 4.33vw;
  font-size: 3.2vw;
  /* color: #9e004c; */
  padding: 2.67vw;
  background: #fff;
  word-break: break-all;
  white-space: normal;
  border-radius: 1.33vw;
}
.voteDetail_card {
  margin: 1.33vw 0;
  border-radius: 1.33vw;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}
.voteDetail_card_block {
  margin: 5.33vw 0;
  width: 92vw;
  padding: 4vw;
  /* border: 1px solid #9e004c; */
  box-shadow: 0 0 0.52vw 0.5vw rgba(158, 0, 76, 0.5);
  border-radius: 2vw;
  justify-content: space-between;
  align-items: center;
}
.voteDetail_card_block_card {
  display: flex;
}
.voteDetail_card_left {
  /* height: 21.33vw; */
  font-size: 3.2vw;
  margin-right: 1.33vw;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.voteDetail_card_item {
  margin: 0.5vw 0;
  width: 50vw;
}
.voteDetail_card_left .desc {
  font-size: 2vw;
}
.voteDetail_card_left .rank {
  margin: 2vw 0;
}
.voteDetail_card_left .vote_number {
  font-weight: bold;
}
.voteDetail_card_right {
  position: relative;
}
.voteDetail_card_right .avater {
  width: 21.33vw;
  height: 21.33vw;
  border-radius: 50%;
  background: #fff;
  border: 1px solid #9e004c;
}
.voteDetail_card_right .avater_new {
  width: 21.33vw;
  height: 21.33vw;
  border-radius: 50%;
  background: #fff;
  border: 1px solid #b7d635;
}

.voteDetail_card_right .trophy {
  width: 6.67vw;
  position: absolute;
  top: 0.53vw;
  left: -0.8vw;
}
.voteDetail_play_block {
  position: relative;
  height: 92vw;
  margin: 0 4vw;
}
.voteDetail_play_cover {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}
.voteDetail_play_icon {
  width: 20vw;
  height: 20vw;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 9;
}
.play_posterImg {
  width: 100%;
  /* height: 92vw; */
  position: absolute;
  top: 0;
  left: 0;
}
.play_module {
  background: rgba(0, 0, 0, 0.3);
  width: 100%;
  /* height: 92vw; */
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
}
.voteDetail_play_go_back {
  margin: 4vw 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.voteDetail_play_go_back .return_btn {
  width: 40%;
  border: none;
  /* margin-top: 5.33vw; */
}
.detail_prompt_info {
  vertical-align: middle;
  height: 12vw;
  padding: 0 5.33vw;
  background: #ede4e9;
  font-size: 2vw;
  line-height: 3.5vw;
  display: flex;
  align-items: center;
}
.voteDetail_handle_vote_block {
  /* width: 30vw; */
  padding: 0 4vw;
  height: 6.4vw;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  /* background: #9e004c; */
  border-radius: 6.4vw;
  position: absolute;
  bottom: 8vw;
  left: 50%;
  transform: translateX(-50%);
}
.voteDetail_handle_vote {
  background: #9e004c;
}
.proficDetail_handle_vote {
  background: #b7d635;
}
.voteDetail_handle_vote_disabled {
  background: #909499;
}

.voteDetail_container video::-webkit-media-controls-fullscreen-button {
  display: block !important;
  content: "\e719";
}
.voteDetail_container .color {
  color: #9e004c;
}
.voteDetail_container .color_new {
  color: #b7d635;
}
.voteDetail_container .background {
  background: #eadce4;
}
.voteDetail_container .background_new {
  background: #f3f8e3;
}
.voteDetail_container .group_vote_btn {
  background: #9e004c;
}
.voteDetail_container .group_vote_btn_new {
  background: #b7d635;
}
.voteDetail_container .card_block_shadow {
  box-shadow: 0 0 1.52vw 0.5vw rgba(158, 0, 76, 0.5);
}
.voteDetail_container .card_block_shadow_new {
  box-shadow: 0 0 1.52vw 0.5vw #b7d635;
}
</style>